import React from 'react';
import {Container, Typography} from "@mui/material";

const Footer = () => {
  return (
    <footer>
      <Container>
        Footer
        <hr />
        <Typography align="center">
          Todos los derechos reservados © 2022
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
