import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
// Screens
import App from '../App';
import Home from '../screen/home';

const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
      </Route>
    </Routes>
  );
};

export default Navigation;
