import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {Typography, IconButton, Menu, MenuItem} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import '../../assets/css/header.css';
import Logo from '../../assets/svg/ticket-logo.svg';
import DefaultImage from '../../assets/img/athletes-posing-in-action.png';

const NavBar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header>
      <div className="background">
        <Container>
          <Box className="header-box">
            <img src={Logo} alt="logo" />
            <div className="nav-bar">
              <Link color="#ffffff" href="#" underline="hover">
                Organizar evento
              </Link>
              <Link color="#ffffff" href="#" underline="hover">
                Quienes somos
              </Link>
              <Link color="#ffffff" href="#" underline="hover" className="button-login">
                Login
              </Link>
            </div>
            <div className="nav-bar-mobile">
              <IconButton
                color="primary"
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: '20ch',
                  },
                }}
              >
                <MenuItem>
                  Organizar evento
                </MenuItem>
                <MenuItem>
                  Quienes somos
                </MenuItem>
                <MenuItem>
                  Login
                </MenuItem>
              </Menu>
            </div>
          </Box>
          <Box className="header-box-content">
            <div className="box-content">
              <img src={DefaultImage} alt="default slider" />
            </div>
            <div className="box-content">
              <Typography variant="h1">
                La mejor plataforma de boletería de Latinoamérica
              </Typography>
              <Typography>
                Descubre todo lo que hay que hacer en tu ciudad, compra o vende entradas y disfruta
              </Typography>
            </div>
          </Box>
        </Container>
      </div>
    </header>
  );
};

export default NavBar;
