import React from 'react';
import DefaultImagePlaceholder from '../../assets/img/default-placeholder.png';
import {EventBoxPropsInterface} from "./interface";

const EventBox = (props: EventBoxPropsInterface) => {
  return (
    <div className="event-box">
      <img src={DefaultImagePlaceholder} alt="default-event" />
      <div className="event-box-content">
        <div className="event-box-date">
          <small>{props.month}</small>
          <p className="date-text">{props.date}</p>
        </div>
        <div className="event-box-text">
          <h3>{props.title}</h3>
          <p className="event-box-text-description">{props.description}</p>
        </div>
      </div>
    </div>
  );
};

export default EventBox;

EventBox.defaultProps = {
  title: 'Title',
  month: 'Abril',
  date: 4,
  description: 'Esta es una descripción',
}
