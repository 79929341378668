import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import Navigation from './navigation';
import reportWebVitals from './reportWebVitals';
import { createTheme } from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#ffffff',
      darker: '#053e85',
    },
    neutral: {
      main: '#ffffff',
      contrastText: '#fff',
    },
  },
});
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Navigation />
    </ThemeProvider>
  </BrowserRouter>
);

reportWebVitals();
