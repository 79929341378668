import React from 'react';
import {Typography, Container} from "@mui/material";
import EventBox from "../../components/eventBox";

const Home = () => {
  const data = Array.from(Array(10).keys());
  return(
    <section className="main-section">
      <Container>
        <Typography variant="h2" alignItems="flex-start">
          Eventos recientes
        </Typography>
        <div className="event-box-container">
          {data.map((el, index) => <EventBox date={index + 1} key={index.toString()} />)}
        </div>
      </Container>
    </section>
  );
};

export default Home;
