import React from 'react';
import NavBar from './components/navBar';
import Search from './components/search';
import Footer from './components/footer';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {Outlet} from "react-router-dom";

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <NavBar />
      <Search />
      <Outlet />
      <Footer />
    </LocalizationProvider>
  );
}

export default App;
