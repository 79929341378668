import React from 'react';
import {TextField, InputAdornment, TextFieldProps} from '@mui/material';
import {Search as SearchIcon} from '@mui/icons-material';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import '../../assets/css/search.css';

const Search = () => {
  const [value, setValue] = React.useState<Date | null>(
    new Date('2014-08-18T21:11:54'),
  );

  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
  };
  return (
    <section id="search">
      <div className="search-container">
        <div className="search-input-container">
          <TextField
            id="standard-basic"
            label="Buscar evento"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary"/>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="search-input-container">
          <TextField
            id="standard-basic"
            label="Ciudad"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary"/>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="search-input-container">
          <DesktopDatePicker
            label="Fecha"
            value={value}
            onChange={handleChange}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
          />
        </div>
      </div>
    </section>
  );
};

export default Search;
